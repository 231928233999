import { css } from '@emotion/react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { fontWeight600 } from '~/css/font'
import { pageModeButtonClasses } from '~/modules/cons-misc/mode-buttons/PageModeButton'
import { IndicatorsButtonClasses } from '~/modules/cons-misc/mode-buttons/IndicatorsButton'
import { chartPositionButtonClasses } from '~/modules/cons-misc/mode-buttons/ChartPositionButton'
import { QtyButtonClasses } from '~/modules/cons-misc/mode-buttons/QtyButton'
import { generateScrollbar } from '~/css/scrollbarCss'

const ButtonGroupMargin = css`
  margin: 2.5px 0px;
`

export const AllCenterCss = css`
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
`

export const SideBarHeadCss = css`
  ${flex.h.allCenter};
  width: 100%;
  height: 30px;
  color: #eeeeee;
  background-color: #5c6e7e;
  font-size: 22px;
  ${fontWeight600};
  border-radius: 5px;
  margin: 1px 0px;
`

export const SideBarCss = css`
  ${flex.v.crossCenter};
  width: 100%;
  height: calc(100% - 5px);
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.7)),
    url('goldbrain/background.png');
  font-size: 22px;
  background-size: 100% 40%;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 2.5px;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  ${generateScrollbar('#454545', '#bbbbbb')}
`
export const LeftContentIndicatorsGroupCss = css`
  ${flex.h.allCenter};
  ${ButtonGroupMargin};
  width: 100%;
  height: 30px;
  gap: 2.5px;
  .${IndicatorsButtonClasses.default} {
    color: #777777;
    background-color: #c7c9d1;
    border: 1px solid #999999;
    &:hover {
      color: #222222;
      background-color: #edeff3;
    }
  }
  .${IndicatorsButtonClasses.selected} {
    color: #222222;
    background-color: #e0e3eb;
    border: 1px solid #5c6e7e;
  }
`
export const LeftContentCostPriceCss = css`
  width: 100%;
  height: 400px;
`
export const LeftContentPositionSwitchGroupCss = css`
  ${flex.h.crossCenter};
  ${ButtonGroupMargin};
  width: 100%;
  height: 30px;
  font-size: 16px;
  gap: 2.5px;
  .${chartPositionButtonClasses.default} {
    &:nth-of-type(1) {
      color: #aaaaaa;
      border: 1px solid #999999;
      background-color: #550000;
    }
  }
  .${chartPositionButtonClasses.selected} {
    &:nth-of-type(1) {
      color: #ffffff;
      border: 1px solid #5b6e7e;
      background-color: #bb0000;
    }
  }
  .${chartPositionButtonClasses.default} {
    &:nth-of-type(2) {
      color: #777777;
      border: 1px solid #999999;
      background-color: #c7c9d1;
    }
  }
  .${chartPositionButtonClasses.selected} {
    &:nth-of-type(2) {
      border: 1px solid #5b6e7e;
      background-color: #e0e3eb;
    }
  }
  .${chartPositionButtonClasses.default} {
    &:nth-of-type(3) {
      color: #aaaaaa;
      border: 1px solid #999999;
      background-color: #005500;
    }
  }

  .${chartPositionButtonClasses.selected} {
    &:nth-of-type(3) {
      color: #ffffff;
      border: 1px solid #5b6e7e;
      background-color: #00bb00;
    }
  }
`
export const RightConentUserInfoCss = css`
  ${flex.h.allCenter};
  width: 100%;
  height: 50px;
`
export const RightConentTrendChartCss = css`
  width: 100%;
  height: 175px;
`
export const RightConentAddStockCss = css`
  ${flex.h.allCenter};
  width: 100%;
  height: 30px;
  font-size: 16px;
`
export const AddStockTitleCss = css`
  ${flex.h.allCenter};
  width: 30%;
  height: 100%;
`
export const AddStockButtonCss = css`
  width: 70%;
  height: 100%;
`
export const RightContentQtyGroupCss = css`
  ${flex.h.allCenter};
  height: 30px;
  font-size: 16px;
  gap: 2.5px;
  .${QtyButtonClasses.default} {
    &:nth-of-type(1) {
      background-color: #550000;
      border: 1px solid #aaaaaa;
      color: #777777;
    }
  }
  .${QtyButtonClasses.selected} {
    &:nth-of-type(1) {
      background-color: #bb0000;
      border: 1px solid #5b6e7e;
    }
  }
  .${QtyButtonClasses.default} {
    &:nth-of-type(2) {
      background-color: #005500;
      border: 1px solid #aaaaaa;
      color: #777777;
    }
  }
  .${QtyButtonClasses.selected} {
    &:nth-of-type(2) {
      background-color: #00bb00;
      border: 1px solid #5b6e7e;
    }
  }
`
export const RightConentPageModeGroupCss = css`
  ${flex.h.allCenter};
  width: 100%;
  height: 30px;
  margin-top: 5px;
  gap: 2.5px;
  .${pageModeButtonClasses.default} {
    color: #777777;
    background-color: #c7c9d1;
    border: 1px solid #999999;
    &:hover {
      background-color: #edeff3;
      color: #222222;
    }
  }
  .${pageModeButtonClasses.selected} {
    color: #222222;
    background-color: #e0e3eb;
    border: 1px solid #5c6e7e;
  }
`
export const RightConentPageModuleCss = css`
  width: 100%;
  height: calc(100% - 310px);
`
export const QuerySettingsCss = css`
  ${flex.h.default};
  width: 100%;
  height: 90px;
`
export const ScreenerTableCss = css`
  width: 100%;
  height: calc(100% - 90px);
`
