import styled from '@emotion/styled'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import React, { memo } from 'react'
import { proxy, useSnapshot } from 'valtio'

export const ButtonStyled = styled.div`
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
`

export type PageMode =
  | 'default'
  | 'userStock'
  | 'fireBaseStock'
  | 'virtualStock'
  | 'popularStock'
  | 'screenerStock'
  | 'notify'
  | string

export const PageModeStore = proxy({
  buttonMode: 'default' as PageMode,
  buttonMode1: 'default' as PageMode,
  buttonMode2: 'default' as PageMode,
  buttonMode3: 'default' as PageMode,
})

export const PageModeButton = memo<
  ReactProps<{
    group: number
    target: PageMode
  }>
>(function PageModeButton(props) {
  const state = useSnapshot(PageModeStore)

  const setState = () => {
    if (props.group === 0) {
      return (PageModeStore.buttonMode = props.target)
    } else if (props.group === 1) {
      return (PageModeStore.buttonMode1 = props.target)
    } else if (props.group === 2) {
      return (PageModeStore.buttonMode2 = props.target)
    } else if (props.group === 3) {
      return (PageModeStore.buttonMode3 = props.target)
    }
  }

  const resultState = () => {
    if (props.group === 0) {
      return state.buttonMode
    } else if (props.group === 1) {
      return state.buttonMode1
    } else if (props.group === 2) {
      return state.buttonMode2
    } else if (props.group === 3) {
      return state.buttonMode3
    }
  }

  const selectedClasses =
    resultState() === props.target ? pageModeButtonClasses.selected : pageModeButtonClasses.default

  return (
    <ButtonStyled
      className={selectedClasses}
      onClick={() => setState()}
    >
      {props.children}
    </ButtonStyled>
  )
})

export const pageModeButtonClasses = {
  default: `PageModeButton-default`,
  selected: `PageModeButton-selected`,
}
